import React from "react";
import ForgotPassword from "../ForgotPassword";
const ForgotPassModal = ({ closeModal, title, message }) => {
  return (
    <>
    <ForgotPassword />
    </>
  );
};

export default ForgotPassModal;
