import { useState } from "react";
import { Form, Button } from "react-bootstrap";

export default function OtpLogin({ onSubmit }) {
  const [mobile, setMobile] = useState("");
  const [consent, setConsent] = useState("");
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit({ mobile, consent });
      }}
    >
      <Form.Group className="text-left">
        <Form.Label>Mobile Number</Form.Label>
        <Form.Control
          type="text"
          name="mobile"
          value={mobile}
          onChange={(event) => setMobile(event.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          name="consent"
          required
        >
          <Form.Check.Input
            type="checkbox"
            value="Yes"
            onChange={(event) => setConsent(event.target.value)}
            required
          />
          <Form.Check.Label>
            I agree to the <a href="/terms-conditions">Terms and Condition</a>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
      <div>
        <Form.Group>
          <Button type="submit" className="w-100 btn btn-default w-100">
            Send OTP
          </Button>
        </Form.Group>
      </div>
    </Form>
  );
}