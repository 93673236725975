import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";

export default function VerificationCodeInput({ onSubmit, onResend }) {
  const [verificationCode, setVerificationCode] = useState("");
  const [displayResend, setDisplayResend] = useState(false);
  useEffect(() => {
    setTimeout(() => {setDisplayResend(true)}, 30000);
  })
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit({ verificationCode });
      }}
    >
      <Form.Group className="text-left">
        <Form.Label>Verification Code</Form.Label>
        <Form.Control
          value={verificationCode}
          onChange={(event) => setVerificationCode(event.target.value)}
          name="verificationCode"
          autoComplete="one-time-code"
          type="text"
          required
        />
      </Form.Group>
      <div className="">
        <Form.Group>
          <Button type="submit" className="request-button">
            Log in
          </Button>{" "}
          {displayResend ? (
            <Button type="button" className="request-button" onClick={onResend}>
            Resend OTP
          </Button>) : ''
          }
        </Form.Group>
      </div>
    </Form>
  );
}