import React from "react";
import { SessionProvider } from "next-auth/react";
import Head from "next/head";
import { Provider } from "react-redux";
import withRedux from "next-redux-wrapper";

import { store } from "../_helpers";
import PageContainer from "../components/Layout";
import ErrorBoundary from "../components/ErrorBoundary";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../styles/whatsapp.css";
import "../styles/globals.css";

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <SessionProvider session={session}>
        <Provider store={store}>
          <PageContainer>
            <ErrorBoundary>
              <Component {...pageProps} />
            </ErrorBoundary>
          </PageContainer>
        </Provider>
      </SessionProvider>
    </>
  );
}

//makeStore function that returns a new store for every request
const makeStore = () => store;

//withRedux wrapper that passes the store to the App Component
export default withRedux(makeStore)(MyApp);
//export default MyApp
