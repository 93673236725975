import { brandConstants } from "../_constants";
import { brandService } from "../_services";

export const brandActions = {
  getBrandList,
};

function getBrandList(category, sortby) {
  return (dispatch) => {
    dispatch(request());

    brandService.getBrandList(category, sortby).then(
      (brands) => dispatch(success(brands)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: brandConstants.BRAND_LIST_REQUEST };
  }
  function success(brands) {
    return { type: brandConstants.BRAND_LIST_SUCCESS, brands };
  }
  function failure(error) {
    return { type: brandConstants.BRAND_LIST_FAILURE, error };
  }
}