import { categoryConstants } from "../_constants";

export function categories(state = {}, action) {
  switch (action.type) {
    case categoryConstants.CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case categoryConstants.CATEGORY_SUCCESS:
      return {
        items: action.stateList,
      };
    case categoryConstants.CATEGORY_FAILURE:
      return {
        error: action.error,
      };
    case categoryConstants.GATE_MAIN_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case categoryConstants.GATE_MAIN_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        mainCategories: action.categories,
      };
    case categoryConstants.GATE_MAIN_CATEGORY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}