import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSession, signIn } from "next-auth/react";

import { hideModal } from "../_actions";
import { authHeader } from "../_helpers";
import BackDrop from "./BackDrop";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import LoginButton from "./LoginButton";
import styles from "../styles/Login.module.css";

const schema = Yup.object({
  username: Yup.string()
    .required("Username is required")
    .email("Please enter valid email address"),
  password: Yup.string().min(8).required("Password is required"),
  confirm_password: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
});

function Registration({ hideModal }) {
  const { data: session, status } = useSession();

  if (status == "authenticated") {
    hideModal();
  }

  const signUp = async (username, password, data_source, email_subscribe) => {
    var formData = new FormData();
    formData.append("email", username);
    formData.append("password", password);
    formData.append("data_source", data_source);
    formData.append("email_subscribe", email_subscribe);
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: formData,
    };
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}auth/register`,
      requestOptions
    );
    const result = await res.json();
    if (res.ok) {
      await signIn("credentials-login", {
        redirect: false,
        username,
        password,
      });
      hideModal();
    } else {
      toast.error(result.message);
    }
  }; 

  return (
    <>
              <Formik
                validationSchema={schema}
                onSubmit={async (values) => {
                  signUp(
                    values.username,
                    values.password,
                    values.data_source,
                    values.email_subscribe
                  );
                }}
                initialValues={{
                  username: "",
                  password: "",
                  confirm_password: "",
                  data_source: "EMAIL_NEWSLETTER",
                  email_subscribe: "1",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Email ID</Form.Label>
                      <Form.Control
                        type="email"
                        name="username"
                        placeholder="youremail@example.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.username && !errors.username}
                        isInvalid={!!errors.username}
                        value={values.username}
                      />
                      <Form.Control.Feedback
                        type={errors.username ? "invalid" : "valid"}
                      >
                        {errors.username ? errors.username : "Looks good!"}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                        value={values.password}
                      />
                      <Form.Control.Feedback
                        type={errors.password ? "invalid" : "valid"}
                      >
                        {errors.password ? errors.password : "Looks good!"}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Re-enter Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirm_password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.confirm_password && !errors.confirm_password
                        }
                        isInvalid={!!errors.confirm_password}
                        value={values.confirm_password}
                      />
                      <Form.Control.Feedback
                        type={errors.confirm_password ? "invalid" : "valid"}
                      >
                        {errors.confirm_password
                          ? errors.confirm_password
                          : "Looks good!"}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Where did you hear about us?</Form.Label>
                      <Form.Control
                        as="select"
                        name="data_source"
                        size="sm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.data_source && !errors.data_source}
                        isInvalid={!!errors.data_source}
                        value={values.data_source}
                      >
                        <option value="EMAIL_NEWSLETTER">
                          Email Newsletter
                        </option>
                        <option value="EXISTING_CUSTOMER">
                          Existing Customer
                        </option>
                        <option value="FAMILY_AND_FRIENDS">
                          Family &amp; Friends
                        </option>
                        <option value="SOCIAL_MEDIA_ADS">
                          Social Media ads
                        </option>
                        <option value="WEB_SEARCH">Web Search</option>
                      </Form.Control>
                      <Form.Control.Feedback
                        type={errors.data_source ? "invalid" : "valid"}
                      >
                        {errors.data_source
                          ? errors.data_source
                          : "Looks good!"}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        name="email_subscribe"
                        defaultChecked
                        label="Subscribe to our newsletter "
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.email_subscribe && !errors.email_subscribe
                        }
                        isInvalid={!!errors.email_subscribe}
                        value={values.email_subscribe}
                      />
                    </Form.Group>
                    {status == "loading" ? (
                      <BackDrop />
                    ) : (
                      <Button type="submit" className="w-100 btn btn-default" disabled={status == "loading"}>
                        Register
                      </Button>
                    )}
                    {/* <Form.Row>
                      Already have an account? <LoginButton />
                    </Form.Row> */}
                  </Form>
                )}
              </Formik>
            
          
        {/* <div className="text-center mt-2">
                      <div>------------------ OR ------------------</div>
                    </div> */}
                    {/* <div className="d-flex flex-column gap-3">
                    <FacebookLoginButton
            onClick={() => {
              signIn("facebook", { redirect: false });
              hideModal();
            }}
          />
          <GoogleLoginButton
            onClick={() => {
              signIn("google", { redirect: false });
              hideModal();
            }}
          />
                    </div> */}
                    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
});

export default connect(null, mapDispatchToProps)(Registration);
