import { authHeader } from "../_helpers";

export const cartService = {
  applyCouponCode,
  submitCart,
};

function applyCouponCode(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}cart/getCouponDetails`,
    requestOptions
  ).then(handleResponse)
    .then((coupon) => {
      let cartItem = JSON.parse(localStorage.getItem("cartContent"));
      cartItem.coupon = coupon;
      cartItem.coupon_id = coupon.id;
      if (coupon.reduction_type === 'fixed') {
        cartItem.coupon_discount = coupon.reduction_amount;
      } else {
        let discountAmount = Math.round(
          (cartItem.price * coupon.reduction_amount) / 100
        );
        if (coupon.max_amount > discountAmount) {
          cartItem.coupon_discount = discountAmount;
        } else {
          cartItem.coupon_discount = coupon.max_amount;
        }
      }
      localStorage.setItem("cartContent", JSON.stringify(cartItem));
      return cartItem;
    });
}

function submitCart(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}cart/checkoutCart`,
    requestOptions
  )
    .then(handleResponse)
    .then((orderDetails) => {
      localStorage.setItem("cartContent", '');
      localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
      return orderDetails;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}