import { authHeader } from "../_helpers";
import * as FormData from "form-data";

export const productService = {
  getProductList,
  getProductDetails,
  getProductValuation,
  applyCouponCode,
  getexistsModel,
  getSeriesOptions,
};

function getProductList(category, brand, sortBy) {
  var formData = new FormData();
  formData.append("catSlug", category);
  formData.append("brandSlug", brand);
  formData.append("sortBy", sortBy);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}products/productList`,
    requestOptions
  ).then(handleResponse);
}

function getProductDetails(product) {
  var formData = new FormData();
  formData.append("prodSlug", product);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}products/productDetails`,
    requestOptions
  ).then(handleResponse);
}

function getSeriesOptions(brand) {
  var formData = new FormData();
  formData.append("brand_id", brand);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/products/seriesOptions`,
    requestOptions
  ).then(handleResponse);
}

function getProductValuation(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}products/productValuation`,
    requestOptions
  )
    .then(handleResponse)
    .then((valuation) => {
      localStorage.setItem("cartContent", JSON.stringify(valuation));
      return valuation;
    });
}

function applyCouponCode(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}products/getCouponDetails`,
    requestOptions
  ).then(handleResponse)
    .then((coupon) => {
      let cartItem = JSON.parse(localStorage.getItem("cartContent"));
      cartItem.coupon = coupon;
      cartItem.coupon_id = coupon.id;
      if (coupon.reduction_type === 'fixed') {
        cartItem.coupon_discount = coupon.reduction_amount;
      } else {
        let discountAmount = Math.round(
          (cartItem.price * coupon.reduction_amount) / 100
        );
        if (coupon.max_amount > discountAmount) {
          cartItem.coupon_discount = discountAmount;
        } else {
          cartItem.coupon_discount = coupon.max_amount;
        }
      }
      localStorage.setItem("cartContent", JSON.stringify(cartItem));
      return cartItem;
    });
}

function getexistsModel(model, brand) {
  var formData = new FormData();
  formData.append("model_name", model);
  formData.append("brand", brand);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}products/getExistsModel`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}