import { orderConstants } from "../_constants";
import { orderService } from "../_services";
import { toast } from "react-toastify";

export const orderActions = {
  getUserOrder,
  updateOrder,
  uploadOrderDocument,
  getCancelReason,
  CancelOrder,
  updateOrderRemittance,
  getOrderWithRemittance,
  getUserOrdersList,
};

function getUserOrder(userId, orderId) {
  return (dispatch) => {
    dispatch(request());

    orderService.getUserOrder(userId, orderId).then(
      (order) => dispatch(success(order)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: orderConstants.GET_ORDER_REQUEST };
  }
  function success(order) {
    return { type: orderConstants.GET_ORDER_SUCCESS, order };
  }
  function failure(error) {
    return { type: orderConstants.GET_ORDER_FAILURE, error };
  }
}

function updateOrder(orderData) {
  return (dispatch) => {
    dispatch(request());

    orderService.updateOrder(orderData).then(
      (order) => {
        dispatch(success(order));
        toast.success("Order update successfully!");
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Error in saving order! try again later.");
      }
    );
  };

  function request() {
    return { type: orderConstants.UPDATE_ORDER_REQUEST };
  }
  function success(order) {
    return { type: orderConstants.UPDATE_ORDER_SUCCESS, order };
  }
  function failure(error) {
    return { type: orderConstants.UPDATE_ORDER_FAILURE, error };
  }
}

function uploadOrderDocument(orderData) {
  return (dispatch) => {
    dispatch(request());

    orderService.uploadOrderDocument(orderData).then(
      (order) => {
        dispatch(success(order));
        toast.success("Order document successfully uploaded!");
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Error in uploading order document! try again later.");
      }
    );
  };

  function request() {
    return { type: orderConstants.UPDATE_ORDER_REQUEST };
  }
  function success(order) {
    return { type: orderConstants.UPDATE_ORDER_SUCCESS, order };
  }
  function failure(error) {
    return { type: orderConstants.UPDATE_ORDER_FAILURE, error };
  }
}

function getCancelReason() {
  return (dispatch) => {
    dispatch(request());

    orderService.getCancelReason().then(
      (reasons) => dispatch(success(reasons)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: orderConstants.CANCEL_REASON_REQUEST };
  }
  function success(reasons) {
    return { type: orderConstants.CANCEL_REASON_SUCCESS, reasons };
  }
  function failure(error) {
    return { type: orderConstants.CANCEL_REASON_FAILURE, error };
  }
}

function CancelOrder(cancelData) {
  return (dispatch) => {
    dispatch(request());

    orderService.CancelOrder(cancelData).then(
      (response) => {
        dispatch(success(response));
        toast.success(response.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Error in order cancelation! try again later.");
      }
    );
  };

  function request() {
    return { type: orderConstants.CANCEL_ORDER_REQUEST };
  }
  function success(response) {
    return { type: orderConstants.CANCEL_ORDER_SUCCESS, response };
  }
  function failure(error) {
    return { type: orderConstants.CANCEL_ORDER_FAILURE, error };
  }
}

function updateOrderRemittance(remittanceData) {
  return (dispatch) => {
    dispatch(request());

    orderService.updateOrderRemittance(remittanceData).then(
      (response) => {
        dispatch(success(response));
        toast.success(response.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Error in update order remittance! try again later.");
      }
    );
  };

  function request() {
    return { type: orderConstants.ORDER_REMITTANCE_REQUEST };
  }
  function success(response) {
    return { type: orderConstants.ORDER_REMITTANCE_SUCCESS, response };
  }
  function failure(error) {
    return { type: orderConstants.ORDER_REMITTANCE_FAILURE, error };
  }
}

function getOrderWithRemittance(orderId, userId) {
  return (dispatch) => {
    dispatch(request());

    orderService.getOrderWithRemittance(orderId, userId).then(
      (order) => dispatch(success(order)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: orderConstants.GET_ORDER_REQUEST };
  }
  function success(order) {
    return { type: orderConstants.GET_ORDER_SUCCESS, order };
  }
  function failure(error) {
    return { type: orderConstants.GET_ORDER_FAILURE, error };
  }
}

function getUserOrdersList(userId) {
  return (dispatch) => {
    dispatch(request());

    orderService.getUserOrdersList(userId).then(
      (orders) => dispatch(success(orders)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: orderConstants.GET_USER_ORDERS_REQUEST };
  }
  function success(orders) {
    return { type: orderConstants.GET_USER_ORDERS_SUCCESS, orders };
  }
  function failure(error) {
    return { type: orderConstants.GET_USER_ORDERS_FAILURE, error };
  }
}