import { userConstants } from '../_constants';
import CookieService from "../_services/cookieService";

let user = CookieService.get("user-token");
const initialState = user ? { loggedIn: true, user } : {};

export function registration(state = initialState, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.REGISTER_FAILURE:
      return {};
    default:
      return state;
  }
}