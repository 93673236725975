export const enquiryConstants = {
  SUBMIT_D2D_REQUEST: "SUBMIT_D2D_REQUEST",
  SUBMIT_D2D_SUCCESS: "SUBMIT_D2D_SUCCESS",
  SUBMIT_D2D_FAILURE: "SUBMIT_D2D_FAILURE",

  D2D_PARTNER_LOGO_REQUEST: "D2D_PARTNER_LOGO_REQUEST",
  D2D_PARTNER_LOGO_SUCCESS: "D2D_PARTNER_LOGO_SUCCESS",
  D2D_PARTNER_LOGO_FAILURE: "D2D_PARTNER_LOGO_FAILURE",

  D2D_PARTNER_CONTENT_REQUEST: "D2D_PARTNER_CONTENT_REQUEST",
  D2D_PARTNER_CONTENT_SUCCESS: "D2D_PARTNER_CONTENT_SUCCESS",
  D2D_PARTNER_CONTENT_FAILURE: "D2D_PARTNER_CONTENT_FAILURE",

  SUBMIT_SALES_ENQUIRY_REQUEST: "SUBMIT_SALES_ENQUIRY_REQUEST",
  SUBMIT_SALES_ENQUIRY_SUCCESS: "SUBMIT_SALES_ENQUIRY_SUCCESS",
  SUBMIT_SALES_ENQUIRY_FAILURE: "SUBMIT_SALES_ENQUIRY_FAILURE",

  ENQUIRY_LAPTOP_OPTION_REQUEST: "ENQUIRY_LAPTOP_OPTION_REQUEST",
  ENQUIRY_LAPTOP_OPTION_SUCCESS: "ENQUIRY_LAPTOP_OPTION_SUCCESS",
  ENQUIRY_LAPTOP_OPTION_FAILURE: "ENQUIRY_LAPTOP_OPTION_FAILURE",

  GET_USER_ENQUIRY_REQUEST: "GET_USER_ENQUIRY_REQUEST",
  GET_USER_ENQUIRY_SUCCESS: "GET_USER_ENQUIRY_SUCCESS",
  GET_USER_ENQUIRY_FAILURE: "GET_USER_ENQUIRY_FAILURE",

  USER_ENQUIRY_REQUEST: "USER_ENQUIRY_REQUEST",
  USER_ENQUIRY_SUCCESS: "USER_ENQUIRY_SUCCESS",
  USER_ENQUIRY_FAILURE: "USER_ENQUIRY_FAILURE",

  ENQUIRY_COUPON_REQUEST: "ENQUIRY_COUPON_REQUEST",
  ENQUIRY_COUPON_SUCCESS: "ENQUIRY_COUPON_SUCCESS",
  ENQUIRY_COUPON_FAILURE: "ENQUIRY_COUPON_FAILURE",

  ACCEPT_QUOTE_REQUEST: "ACCEPT_QUOTE_REQUEST",
  ACCEPT_QUOTE_SUCCESS: "ACCEPT_QUOTE_SUCCESS",
  ACCEPT_QUOTE_FAILURE: "ACCEPT_QUOTE_FAILURE",

  SUBMIT_CONTACT_REQUEST: "SUBMIT_CONTACT_REQUEST",
  SUBMIT_CONTACT_SUCCESS: "SUBMIT_CONTACT_SUCCESS",
  SUBMIT_CONTACT_FAILURE: "SUBMIT_CONTACT_FAILURE",
};
