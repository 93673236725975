import { locationConstants } from "../_constants";
import { locationService } from "../_services";

export const locationActions = {
  getStateList
};

function getStateList() {
  return (dispatch) => {
    dispatch(request());

    locationService.getStateList().then(
      (stateList) => dispatch(success(stateList)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: locationConstants.GETALL_REQUEST };
  }
  function success(stateList) {
    return { type: locationConstants.GETALL_SUCCESS, stateList };
  }
  function failure(error) {
    return { type: locationConstants.GETALL_FAILURE, error };
  }
}