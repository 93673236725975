import React, { Component } from "react";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { Form, Col, Button, Card, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { enquiryActions } from "../_actions";
import moment from "moment";
import Icon from "react-icons-kit";
import { rupee } from "react-icons-kit/fa";
import { plus } from "react-icons-kit/fa";
import BackDrop from "./BackDrop";
import styles from '../styles/products.module.css';

const schema = Yup.object().shape({
  preferred_date: Yup.string().required("required"),
  terms: Yup.array().of(
    Yup.object().shape({
      id_proof: Yup.string().required("Please accept this terms to continue"),
      declaration_form: Yup.string().required(
        "Please accept this terms to continue"
      ),
      ready_package: Yup.string().required(
        "Please accept this terms to continue"
      ),
      clear_data: Yup.string().required("Please accept this terms to continue"),
      self_declaration: Yup.string().required(
        "Please accept this terms to continue"
      ),
    })
  ),
});
class AcceptPriceQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponData: [],
      coupon_discount: 0,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { couponData } = nextProps;
    if (couponData) {
      this.setState({
        coupon_discount: couponData.coupon_amount,
        couponData: couponData,
      });
    }
  }
  render() {
    const {
      submitting,
      accepting,
      closeModal,
      enquiryDetails,
      activeQuote,
      user,
      priceQuoteId,
      acceptStatus,
      modal,
    } = this.props;
    const { coupon_discount, couponData } = this.state;
    const dateArr = [];
    const tomorrow = moment().add("1", "day");
    const nextDay = moment().add("2", "day");
    dateArr.push(tomorrow);
    dateArr.push(nextDay);
    let deviceValue = activeQuote.price_quote
      ? parseInt(activeQuote.price_quote)
      : 0;
    let couponAmount = coupon_discount ? coupon_discount : 0;
    let totalAmount = parseInt(deviceValue) + parseInt(couponAmount);
    if (acceptStatus) {
      if (modal) {
        closeModal();
      }
      window.location.reload(1);
    }
    return (
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card className={styles.card_product_form}>
                <Card.Header>Do you have any coupon?</Card.Header>
                <Card.Body>
                  <Formik
                    validationSchema={Yup.object({
                      coupon_code: Yup.string()
                        .required("Coupon code is required")
                        .min(4, "Minimum 4 Character required"),
                    })}
                    onSubmit={async (values) => {
                      var formData = new FormData();
                      formData.append("enquiry_id", enquiryDetails.enquiry_id);
                      formData.append("coupon_code", values.coupon_code);

                      this.props.applyCouponCode(formData);
                    }}
                    initialValues={{
                      coupon_code: "",
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isInvalid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} md="6">
                            <Form.Control
                              type="text"
                              name="coupon_code"
                              placeholder="Enter Code"
                              id="coupon_code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={
                                touched.coupon_code && !errors.coupon_code
                              }
                              isInvalid={!!errors.coupon_code}
                              value={values.coupon_code}
                            />
                            <Form.Control.Feedback
                              type={errors.coupon_code ? "invalid" : "valid"}
                            >
                              {errors.coupon_code
                                ? errors.coupon_code
                                : "Looks good!"}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group>
                            {submitting ? (
                              <BackDrop />
                            ) : (
                              <Button type="submit">Apply</Button>
                            )}
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Card className={styles.card_product_form}>
                <Card.Body>
                  <Row>
                    <Col className={styles.blue_text}>Device Value:</Col>
                    <Col className={styles.text_ar}>
                      <Icon icon={rupee} />
                      {deviceValue}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={styles.blue_text}>Coupon Amount:</Col>
                    <Col className={styles.text_ar}>
                      <Icon icon={plus} />
                      <Icon icon={rupee} />
                      {couponAmount}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col className={styles.blue_text}>Final Value:</Col>
                    <Col className={styles.text_ar}>
                      <Icon icon={rupee} />
                      {totalAmount}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Formik
                        validationSchema={schema}
                        onSubmit={async (values) => {
                          var formData = new FormData();
                          for (let [key, value] of Object.entries(values)) {
                            if (key === "terms") {
                              value = JSON.stringify(value, null, 2);
                            }
                            formData.append(key, value);
                          }
                          formData.append(
                            "enquiry_id",
                            enquiryDetails.enquiry_id
                          );
                          formData.append(
                            "coupon_data",
                            JSON.stringify(couponData, null, 2)
                          );
                          formData.append("price_quote_id", priceQuoteId);
                          formData.append("user_id", enquiryDetails.party_id);

                          this.props.acceptQuote(formData);
                        }}
                        initialValues={{
                          preferred_date: "",
                          terms: [
                            {
                              id_proof: "",
                              declaration_form: "",
                              ready_package: "",
                              clear_data: "",
                              self_declaration: "",
                            },
                          ],
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isInvalid,
                          errors,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <FieldArray name="terms">
                              {() => {
                                let index = 0;
                                return (
                                  <>
                                    <Form.Row>
                                      <Form.Group>
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name={`terms.${index}.id_proof`}
                                            value="Keep self attested copies of Id and 
                                Address Proof along with the gadget. 
                              (Aadhaar Card/Drivers' License/Passport)."
                                          />{" "}
                                          Keep self attested copies of Id and
                                          Address Proof along with the gadget.
                                          (Aadhaar Card/Drivers'
                                          License/Passport).
                                        </label>
                                        <ErrorMessage
                                          name={`terms.${index}.id_proof`}
                                          component="div"
                                          className="error"
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                      <Form.Group>
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name={`terms.${index}.declaration_form`}
                                            value="Keep a signed copy of the Indemnity Bond along with the gadget."
                                          />{" "}
                                          Keep a signed copy of the Indemnity
                                          Bond along with the gadget.
                                          <a
                                            id="display_declaration"
                                            href="https://budlisell.s3.ap-south-1.amazonaws.com/uploads/sample_files/declaration_form.pdf"
                                          >
                                            here
                                          </a>
                                        </label>
                                        <ErrorMessage
                                          name={`terms.${index}.declaration_form`}
                                          component="div"
                                          className="error"
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                      <Form.Group>
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name={`terms.${index}.ready_package`}
                                            value="Keep the product ready before the scheduled pickup date/time."
                                          />{" "}
                                          Keep the product ready before the
                                          scheduled pickup date/time.Click{" "}
                                          <a
                                            id="display_packing_info"
                                            href="https://budlisell.s3.ap-south-1.amazonaws.com/assets/img/packing_info.jpg"
                                          >
                                            here
                                          </a>{" "}
                                          for packaging guidelines.
                                        </label>
                                        <ErrorMessage
                                          name={`terms.${index}.ready_package`}
                                          component="div"
                                          className="error"
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                      <Form.Group>
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name={`terms.${index}.clear_data`}
                                            value="I have removed all data and passwords from the gadget."
                                          />{" "}
                                          I have removed all data and passwords
                                          from the gadget.
                                        </label>
                                        <ErrorMessage
                                          name={`terms.${index}.clear_data`}
                                          component="div"
                                          className="error"
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                      <Form.Group>
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name={`terms.${index}.self_declaration`}
                                            value="I confirm that I have mentioned accurate condition of my gadget. 
                              If any discrepancy is found, I may be liable to pay the difference 
                              in price or return charges for the gadget."
                                          />{" "}
                                          I confirm that I have mentioned
                                          accurate condition of my gadget. If
                                          any discrepancy is found, I may be
                                          liable to pay the difference in price
                                          or return charges for the gadget.
                                        </label>
                                        <ErrorMessage
                                          name={`terms.${index}.self_declaration`}
                                          component="div"
                                          className="error"
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                  </>
                                );
                              }}
                            </FieldArray>
                            <Form.Row>
                              <Form.Group as={Col} md="6">
                                <Form.Label>Preferred Pickup Date</Form.Label>
                                <Field
                                  id="preferred_date"
                                  name="preferred_date"
                                >
                                  {({
                                    field, // { name, value, onChange, onBlur }

                                    form: { values, touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.

                                    meta,
                                  }) => (
                                    <>
                                      <Form.Control
                                        as="select"
                                        {...field}
                                        isInvalid={!!errors[field.name]}
                                        isValid={
                                          touched[field.name] &&
                                          !errors[field.name]
                                        }
                                        value={values.preferred_date}
                                      >
                                        <option value="">Select One</option>
                                        {dateArr &&
                                          dateArr.map((d, index) => (
                                            <option
                                              key={index}
                                              value={d.format("YYYY-MM-DD")}
                                            >
                                              {d.format("DD/MM/YYYY")}
                                            </option>
                                          ))}
                                      </Form.Control>
                                      <Form.Control.Feedback
                                        type={
                                          errors[field.name]
                                            ? "invalid"
                                            : "valid"
                                        }
                                      >
                                        {errors[field.name]
                                          ? errors[field.name]
                                          : "Looks good!"}
                                      </Form.Control.Feedback>
                                    </>
                                  )}
                                </Field>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Col>
                                {accepting ? (
                                  <BackDrop />
                                ) : (
                                  <Button type="submit">Confirm</Button>
                                )}{" "}
                                {modal ? (
                                  <Button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={closeModal}
                                  >
                                    Close
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Form.Row>
                          </Form>
                        )}
                      </Formik>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

const mapState = (state) => ({
  user: state.authentication.user,
  submitting: state.enquiries.submitting,
  accepting: state.enquiries.accepting,
  couponData: state.enquiries.couponData,
  acceptStatus: state.enquiries.acceptStatus,
});

const actionCreators = {
  applyCouponCode: enquiryActions.applyCouponCode,
  acceptQuote: enquiryActions.acceptQuote,
};

export default connect(mapState, actionCreators)(AcceptPriceQuote);