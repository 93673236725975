import { connect } from "react-redux";
import ModalRoot from "./ModalRoot";
import { showModal, hideModal } from "../_actions";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ScrollTop from "./ScrollTop";
import dynamic from "next/dynamic";
const Whatsapp = dynamic(() => import("./Whatsapp"));
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";


const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

const PageContainer = (props) => {
  const { innerPage, deviceType } = props;
  const ismobiletype = isMobile ? "mobile" : "desktop";
  const ismobile = deviceType ? deviceType : ismobiletype;
  /////////////////
  const [screenWidth, setScreenWidth] = useState(0);
  const [isMyMobile, setIsMyMobile] = useState(false)

  useEffect(()=>{
    setScreenWidth(window.innerWidth);
  },[])

useEffect(() => {
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
useEffect(()=>{
  setIsMyMobile(screenWidth <= 991)
},[screenWidth])
///////////////////////////


  return (
    <>
    
    <div id="back-to-top-anchor"></div>
    <div className="sticky_barmar"></div>

      <div className="main_content">{props.children}</div>
      <ModalRoot hideModal={props.hideModal} />
      <ToastContainer autoClose={false} />
      <ScrollTop></ScrollTop>
      <Whatsapp />
    </>
  );
};

export default connect(null, mapDispatchToProps)(PageContainer);
//export default layout;
