import { enquiryConstants } from "../_constants";

export function enquiries(state = {}, action) {
  switch (action.type) {
    case enquiryConstants.SUBMIT_D2D_REQUEST:
      return {
        d2dSaving: true,
      };
    case enquiryConstants.SUBMIT_D2D_SUCCESS:
      return {
        ...state,
        d2dSaving: false,
        saveStatus: action.status,
      };
    case enquiryConstants.SUBMIT_D2D_FAILURE:
      return {
        error: action.error,
      };
    case enquiryConstants.D2D_PARTNER_LOGO_REQUEST:
      return {
        loading: true,
      };
    case enquiryConstants.D2D_PARTNER_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        logos: action.logos,
      };
    case enquiryConstants.D2D_PARTNER_LOGO_FAILURE:
      return {
        error: action.error,
      };
    case enquiryConstants.D2D_PARTNER_CONTENT_REQUEST:
      return {
        loading: true,
      };
    case enquiryConstants.D2D_PARTNER_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        partnerContent: action.content,
      };
    case enquiryConstants.D2D_PARTNER_CONTENT_FAILURE:
      return {
        error: action.error,
      };
    case enquiryConstants.SUBMIT_SALES_ENQUIRY_REQUEST:
      return {
        savingEnquiry: true,
      };
    case enquiryConstants.SUBMIT_SALES_ENQUIRY_SUCCESS:
      return {
        ...state,
        savingEnquiry: false,
        saveStatus: action.status,
      };
    case enquiryConstants.SUBMIT_SALES_ENQUIRY_FAILURE:
      return {
        error: action.error,
      };
    case enquiryConstants.ENQUIRY_LAPTOP_OPTION_REQUEST:
      return {
        loading: true,
      };
    case enquiryConstants.ENQUIRY_LAPTOP_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        laptopOption: action.options,
      };
    case enquiryConstants.ENQUIRY_LAPTOP_OPTION_FAILURE:
      return {
        error: action.error,
      };
    case enquiryConstants.GET_USER_ENQUIRY_REQUEST:
      return {
        loading: true,
      };
    case enquiryConstants.GET_USER_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiries: action.enquiries,
      };
    case enquiryConstants.GET_USER_ENQUIRY_FAILURE:
      return {
        error: action.error,
      };
    case enquiryConstants.USER_ENQUIRY_REQUEST:
      return {
        loading: true,
      };
    case enquiryConstants.USER_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiry: action.enquiry,
      };
    case enquiryConstants.USER_ENQUIRY_FAILURE:
      return {
        error: action.error,
      };
    case enquiryConstants.ENQUIRY_COUPON_REQUEST:
      return {
        submitting: true,
      };
    case enquiryConstants.ENQUIRY_COUPON_SUCCESS:
      return {
        ...state,
        submitting: false,
        couponData: action.couponData,
      };
    case enquiryConstants.ENQUIRY_COUPON_FAILURE:
      return {
        error: action.error,
      };
    case enquiryConstants.ACCEPT_QUOTE_REQUEST:
      return {
        accepting: true,
      };
    case enquiryConstants.ACCEPT_QUOTE_SUCCESS:
      return {
        ...state,
        accepting: false,
        acceptStatus: action.status,
      };
    case enquiryConstants.ACCEPT_QUOTE_FAILURE:
      return {
        error: action.error,
      };
    case enquiryConstants.SUBMIT_CONTACT_REQUEST:
      return {
        contactSaving: true,
      };
    case enquiryConstants.SUBMIT_CONTACT_SUCCESS:
      return {
        ...state,
        contactSaving: false,
        contactUsStatus: action.status,
      };
    case enquiryConstants.SUBMIT_CONTACT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}