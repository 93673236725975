import { cartConstants } from "../_constants";

export function cart(state = {}, action) {
  switch (action.type) {
    case cartConstants.APLY_COUPON_REQUEST:
      return {
        submitting: true,
      };
    case cartConstants.APLY_COUPON_SUCCESS:
      return {
        ...state,
        submitting: false,
        cartContent: action.cartItem,
      };
    case cartConstants.APLY_COUPON_FAILURE:
      return {
        error: action.error,
      };
    case cartConstants.SUBMIT_CART_REQUEST:
      return {
        cartSubmitting: true,
      };
    case cartConstants.SUBMIT_CART_SUCCESS:
      return {
        ...state,
        //cartSubmitting: false,
        orderDetails: action.orderDetails,
      };
    case cartConstants.SUBMIT_CART_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}