import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import modal from "./modal.reducer";
import {locations} from "./locations.reducer";
import {orders} from "./orders.reducer";
import {remittance} from "./remittance.reducer";
import catalog from "./catalog.reducer";
import { cart } from "./cart.reducer";
import { enquiries } from "./enquiry.reducer";
import { documents } from './document.reducer'

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  modal,
  locations,
  orders,
  remittance,
  catalog,
  cart,
  enquiries,
  documents,
});

export default rootReducer;