export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETPROFILE_REQUEST: "USERS_GETPROFILE_REQUEST",
  GETPROFILE_SUCCESS: "USERS_GETPROFILE_SUCCESS",
  GETPROFILE_FAILURE: "USERS_GETPROFILE_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  UPDATE_REQUEST: "USERS_UPDATE_REQUEST",
  UPDATE_SUCCESS: "USERS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "USERS_UPDATE_FAILURE",

  CHANGE_PASSWORD_REQUEST: "PASSWORD_UPDATE_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "PASSWORD_UPDATE_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "PASSWORD_UPDATE_FAILURE",
  
  FORGOT_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  NEWSLETTER_SIGNUP_REQUEST: "NEWSLETTER_SIGNUP_REQUEST",
  NEWSLETTER_SIGNUP_SUCCESS: "NEWSLETTER_SIGNUP_SUCCESS",
  NEWSLETTER_SIGNUP_FAILURE: "NEWSLETTER_SIGNUP_FAILURE",
};
