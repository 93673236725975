import { authHeader } from '../_helpers';
import CookieService from "./cookieService";

export const userService = {
  login,
  socialLogin,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  changePassword,
  changeEmail,
  resetPassword,
  newsletterSignup,
};

const expireAt = 30 * 60 * 24;
let date = new Date();
date.setTime(date.getTime() + (expireAt * 60 * 1000));
const options = { path: "/", expires: date };

function login(username, password) {
    var formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    const requestOptions = {
      method: "POST",
      headers: { "X-API-KEY": process.env.NEXT_PUBLIC_API_KEY },
      body: formData,
    };

    return fetch(`${process.env.NEXT_PUBLIC_API_URL}auth/login`, requestOptions)
      .then(handleResponse)
        .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
          //localStorage.setItem("user", JSON.stringify(user));
          CookieService.setCookie("user-token", JSON.stringify(user), options);
        return user;
      });
}

function socialLogin(user, provider) {
  var formData = new FormData();
  formData.append("external_auth_provider", provider);
  formData.append("email", user.email);
  formData.append("firstName", user.firstName);
  formData.append("lastName", user.lastName);
  if (provider === 'facebook') {
    formData.append("fb_id", user.id);
  }
  const requestOptions = {
    method: "POST",
    headers: { "X-API-KEY": process.env.NEXT_PUBLIC_API_KEY },
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}auth/socialLogin`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //localStorage.setItem("user", JSON.stringify(user));
      CookieService.setCookie("user-token", JSON.stringify(user), options);
      return user;
    });
}

function logout() {
    // remove user from local storage to log user out
  CookieService.remove("user-token", options);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.NEXT_PUBLIC_API_URL}/users`, requestOptions).then(
      handleResponse
    );
}

function getById(id) {
  var formData = new FormData();
  formData.append("userId", id);
    const requestOptions = {
        method: 'POST',
      headers: authHeader(),
        body: formData,
    };

    return fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/auth/userProfile/${id}`,
      requestOptions
    ).then(handleResponse);
}

function register(username, password, data_source, email_subscribe) {
  var formData = new FormData();
  formData.append("email", username);
  formData.append("password", password);
  formData.append("data_source", data_source);
  formData.append("email_subscribe", email_subscribe);
  const requestOptions = {
    method: "POST",
    headers: { "X-API-KEY": process.env.NEXT_PUBLIC_API_KEY },
    body: formData,
  };

  return fetch(`${process.env.NEXT_PUBLIC_API_URL}/auth/register`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      CookieService.setCookie("user-token", JSON.stringify(user), options);
      return user;
    });
}

function update( firstName,lastName,phone,address2,address1,city,state,pincode, userId) {
  var formData = new FormData();
  formData.append("firstname", firstName);
  formData.append("lastname", lastName);
  formData.append("phone", phone);
  formData.append("address1", address1);
  formData.append("address2", address2);
  formData.append("city", city);
  formData.append("zone_id", state);
  formData.append("zip", pincode);
  formData.append("userId", userId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/auth/updateProfile`,
    requestOptions
  ).then(handleResponse);
}

function changePassword(newPassword, confirmPassword, userId) {
  var formData = new FormData();
  formData.append("password", newPassword);
  formData.append("confirm", confirmPassword);
  formData.append("userId", userId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/auth/updatePassword`,
    requestOptions
  ).then(handleResponse);
}

function changeEmail(newEmail, confirmEmail, userId) {
  var formData = new FormData();
  formData.append("email", newEmail);
  formData.append("confirm", confirmEmail);
  formData.append("userId", userId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/auth/updateEmail`,
    requestOptions
  ).then(handleResponse)
    .then((user) => {
      return user;
    });;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/users/${id}`,
      requestOptions
    ).then(handleResponse);
}

function resetPassword(email) {
  var formData = new FormData();
  formData.append("email", email);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/auth/forgotPassword`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function newsletterSignup(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}newsletter_signup/subscription`,
    requestOptions
  )
    .then(handleResponse)
    .then((status) => {
      return status;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}