import { authHeader } from "../_helpers";
import * as FormData from "form-data";

export const documentService = {
  getUploadDocumentTypes,
  uploadUserDocument,
  getUserDocument,
  deleteUserDocument,
};

function getUploadDocumentTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/documents/userUploadDocumentTypes`,
    requestOptions
  ).then(handleResponse);
}

function uploadUserDocument(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/documents/saveUserDocs`,
    requestOptions
  ).then(handleResponse);
}

function getUserDocument(userId) {
  var formData = new FormData();
  formData.append("userId", userId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/documents/getUserDocs`,
    requestOptions
  ).then(handleResponse);
}

function deleteUserDocument(documentId) {
  var formData = new FormData();
  formData.append("document_id", documentId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/documents/deleteUserDocument`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}