import { productConstants } from "../_constants";
var isNode = typeof module !== "undefined";
let cartContent = '';
if (!isNode) {
  cartContent = localStorage.getItem("cartContent");
}
const initialState = {
  appliedFilters: "view_all",
  filteredProducts: [],
  cartContent: cartContent ? JSON.parse(cartContent) : {}
};
export function products(state = initialState, action) {
  switch (action.type) {
    case productConstants.PRODUCT_LIST_REQUEST:
      return {
        loading: true,
      };
    case productConstants.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productList: action.products,
      };
    case productConstants.PRODUCT_LIST_FAILURE:
      return {
        error: action.error,
      };
    case productConstants.PRODUCT_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case productConstants.PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.productDetails,
      };
    case productConstants.PRODUCT_DETAILS_FAILURE:
      return {
        error: action.error,
      };
    case productConstants.PRODUCT_VALUATION_REQUEST:
      return {
        loading: true,
      };
    case productConstants.PRODUCT_VALUATION_SUCCESS:
      return {
        ...state,
        valuation: action.productValuation,
      };
    case productConstants.PRODUCT_VALUATION_FAILURE:
      return {
        error: action.error,
      };
    case productConstants.GET_EXIST_MODEL_REQUEST:
      return {
        loading: true,
      };
    case productConstants.GET_EXIST_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        modelData: action.modelData,
      };
    case productConstants.GET_EXIST_MODEL_FAILURE:
      return {
        error: action.error,
      };
    case productConstants.GET_SERIES_OPTIONS_REQUEST:
      return {
        loadingSeries: true,
      };
    case productConstants.GET_SERIES_OPTIONS_SUCCESS:
      return {
        ...state,
        loadingSeries: false,
        seriesOptions: action.seriesOptions,
      };
    case productConstants.GET_SERIES_OPTIONS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}