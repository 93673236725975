export const orderConstants = {
  GET_ORDER_REQUEST: "GET_ORDER_REQUEST",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_FAILURE: "GET_ORDER_FAILURE",

  UPDATE_ORDER_REQUEST: "UPDATE_ORDER_REQUEST",
  UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
  UPDATE_ORDER_FAILURE: "UPDATE_ORDER_FAILURE",

  CANCEL_REASON_REQUEST: "CANCEL_REASON_REQUEST",
  CANCEL_REASON_SUCCESS: "CANCEL_REASON_SUCCESS",
  CANCEL_REASON_FAILURE: "CANCEL_REASON_FAILURE",

  CANCEL_ORDER_REQUEST: "CANCEL_ORDER_REQUEST",
  CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
  CANCEL_ORDER_FAILURE: "CANCEL_ORDER_FAILURE",

  ORDER_REMITTANCE_REQUEST: "ORDER_REMITTANCE_REQUEST",
  ORDER_REMITTANCE_SUCCESS: "ORDER_REMITTANCE_SUCCESS",
  ORDER_REMITTANCE_FAILURE: "ORDER_REMITTANCE_FAILURE",

  GET_USER_ORDERS_REQUEST: "GET_USER_ORDERS_REQUEST",
  GET_USER_ORDERS_SUCCESS: "GET_USER_ORDERS_SUCCESS",
  GET_USER_ORDERS_FAILURE: "GET_USER_ORDERS_FAILURE",
};
