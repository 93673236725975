import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal, hideModal } from "../_actions";

class LoginButton extends Component {
  constructor(props) {
    super(props);

    this.openLoginModal = this.openLoginModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openLoginModal() {
    this.props.showModal(
      {
        open: true,
        title: "",
        message: "",
        size: "md",
        closeModal: this.closeModal,
      },
      "login"
    );
  }
  closeModal() {
    this.props.hideModal();
  }
  render() {
    return <a onClick={this.openLoginModal}>Login</a>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(null, mapDispatchToProps)(LoginButton);
