import { combineReducers } from "redux";
import { categories } from "./category.reducer";
import { brands } from "./brand.reducer";
import {products} from './product.reducer'

const catalog = combineReducers({
    categories: categories,
    brands: brands,
    products: products
})

export default catalog;