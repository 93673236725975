export const documentConstants = {
  DOCUMENT_TYPES_REQUEST: "DOCUMENT_TYPES_REQUEST",
  DOCUMENT_TYPES_SUCCESS: "DOCUMENT_TYPES_SUCCESS",
  DOCUMENT_TYPES_FAILURE: "DOCUMENT_TYPES_FAILURE",

  UPLOAD_USER_DOCUMENT_REQUEST: "UPLOAD_USER_DOCUMENT_REQUEST",
  UPLOAD_USER_DOCUMENT_SUCCESS: "UPLOAD_USER_DOCUMENT_SUCCESS",
  UPLOAD_USER_DOCUMENT_FAILURE: "UPLOAD_USER_DOCUMENT_FAILURE",

  USER_DOCUMENT_REQUEST: "USER_DOCUMENT_REQUEST",
  USER_DOCUMENT_SUCCESS: "USER_DOCUMENT_SUCCESS",
  USER_DOCUMENT_FAILURE: "USER_DOCUMENT_FAILURE",

  DELETE_USER_DOCUMENT_REQUEST: "DELETE_USER_DOCUMENT_REQUEST",
  DELETE_USER_DOCUMENT_SUCCESS: "DELETE_USER_DOCUMENT_SUCCESS",
  DELETE_USER_DOCUMENT_FAILURE: "DELETE_USER_DOCUMENT_FAILURE",
};
