import { productConstants } from "../_constants";
import { productService } from "../_services";
import Router from "next/router";
import { toast } from "react-toastify";
//import { history } from '../_helpers';

export const productActions = {
  getProductList,
  getProductDetails,
  getProductValuation,
  getExistsModel,
  getSeriesOptions,
};

function getProductList(category, brand, sortBy) {
  return (dispatch) => {
    dispatch(request());

    productService.getProductList(category, brand, sortBy).then(
      (products) => dispatch(success(products)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: productConstants.PRODUCT_LIST_REQUEST };
  }
  function success(products) {
    return { type: productConstants.PRODUCT_LIST_SUCCESS, products };
  }
  function failure(error) {
    return { type: productConstants.PRODUCT_LIST_FAILURE, error };
  }
}

function getProductDetails(product) {
  return (dispatch) => {
    dispatch(request());

    productService.getProductDetails(product).then(
      (productDetails) => dispatch(success(productDetails)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: productConstants.PRODUCT_DETAILS_REQUEST };
  }
  function success(productDetails) {
    return { type: productConstants.PRODUCT_DETAILS_SUCCESS, productDetails };
  }
  function failure(error) {
    return { type: productConstants.PRODUCT_DETAILS_FAILURE, error };
  }
}

function getProductValuation(formData) {
  return (dispatch) => {
    dispatch(request());

    productService.getProductValuation(formData).then(
      (productValuation) => {
        dispatch(success(productValuation));
        Router.push(`/gadget-value`);
      },
      (error) => {
        dispatch(failure(error.toString()));
          const CustomToastWithHtml = () => (
              <div dangerouslySetInnerHTML={{ __html: error }}></div>
            );
            toast.error(CustomToastWithHtml);
      }
    );
  };

  function request() {
    return { type: productConstants.PRODUCT_VALUATION_REQUEST };
  }
  function success(productValuation) {
    return {
      type: productConstants.PRODUCT_VALUATION_SUCCESS,
      productValuation,
    };
  }
  function failure(error) {
    return { type: productConstants.PRODUCT_VALUATION_FAILURE, error };
  }
}

function getExistsModel(model, brand) {
  return (dispatch) => {
    dispatch(request());

    productService.getexistsModel(model, brand).then(
      (modelData) => dispatch(success(modelData)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: productConstants.GET_EXIST_MODEL_REQUEST };
  }
  function success(modelData) {
    return { type: productConstants.GET_EXIST_MODEL_SUCCESS, modelData };
  }
  function failure(error) {
    return { type: productConstants.GET_EXIST_MODEL_FAILURE, error };
  }
}

function getSeriesOptions(brand) {
  return (dispatch) => {
    dispatch(request());

    productService.getSeriesOptions(brand).then(
      (seriesOptions) => dispatch(success(seriesOptions)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: productConstants.GET_SERIES_OPTIONS_REQUEST };
  }
  function success(seriesOptions) {
    return { type: productConstants.GET_SERIES_OPTIONS_SUCCESS, seriesOptions };
  }
  function failure(error) {
    return { type: productConstants.GET_SERIES_OPTIONS_FAILURE, error };
  }
}