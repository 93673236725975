import * as FormData from "form-data";
import { authHeader } from "../_helpers";

export const brandService = {
  getBrandList,
};

function getBrandList(category, sortby) {
  var formData = new FormData();
  formData.append("catSlug", category);
  formData.append("sortBy", sortby);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/brands/brandList`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}