import React from "react";
import { connect } from "react-redux";
//import ReactModal from "react-modal";
import { Modal } from "react-bootstrap";

import { default as modalTypes } from "./Modals";

const MODAL_TYPES = {
  alert: modalTypes.alertModal,
  login: modalTypes.LoginModal,
  registration: modalTypes.RegistrationModal,
  acceptquote: modalTypes.AcceptQuoteModal,
  forgotpass: modalTypes.ForgotPassModal,
};

const mapStateToProps = (state) => ({
  ...state.modal,
})

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: props.modalProps.open,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modalProps.open !== this.props.modalProps.open) {
      this.setState({
        modalIsOpen: nextProps.modalProps.open,
      });
    }
  }

  closeModal() {
    this.props.hideModal();
  }

  render() {
    if (!this.props.modalType) {
      return null;
    }
    const SpecifiedModal = MODAL_TYPES[this.props.modalType];
    return (
      <div>
        <Modal
          show={this.state.modalIsOpen}
          onHide={this.closeModal}
          backdrop="static"
          keyboard={false}
          size={this.props.modalProps.size}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.modalProps.title}</Modal.Title>
          </Modal.Header>
          <SpecifiedModal
            closeModal={this.closeModal}
            {...this.props.modalProps}
          />
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(ModalContainer);