import { documentConstants } from "../_constants";

export function documents(state = {}, action) {
  switch (action.type) {
    case documentConstants.DOCUMENT_TYPES_REQUEST:
      return {
        loading: true,
      };
    case documentConstants.DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        documentTypes: action.documentsTypes,
      };
    case documentConstants.DOCUMENT_TYPES_FAILURE:
      return {
        error: action.error,
      };
    case documentConstants.UPLOAD_USER_DOCUMENT_REQUEST:
      return {
        uploading: true,
      };
    case documentConstants.UPLOAD_USER_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadStatus: action.uploadStatus,
      };
    case documentConstants.UPLOAD_USER_DOCUMENT_FAILURE:
      return {
        error: action.error,
      };
    case documentConstants.USER_DOCUMENT_REQUEST:
      return {
        fetchingDocument: true,
      };
    case documentConstants.USER_DOCUMENT_SUCCESS:
      return {
        ...state,
        fetchingDocument: false,
        documents: action.documents,
      };
    case documentConstants.USER_DOCUMENT_FAILURE:
      return {
        documents: [],
        error: action.error,
      };
    case documentConstants.DELETE_USER_DOCUMENT_REQUEST:
      return {
        deleteing: true,
      };
    case documentConstants.DELETE_USER_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteing: false,
        deleteStatus: action.deleteStatus,
      };
    case documentConstants.DELETE_USER_DOCUMENT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}