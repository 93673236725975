import React from "react";
import AcceptPriceQuote from "../AcceptPriceQuote";
const AcceptQuoteModal = ({
  closeModal,
  title,
  message,
  enquiryDetails,
  activeQuote,
  priceQuoteId,
}) => {
  return (
    <AcceptPriceQuote
      enquiryDetails={enquiryDetails}
      activeQuote={activeQuote}
      closeModal={closeModal}
      priceQuoteId={priceQuoteId}
    />
  );
};

export default AcceptQuoteModal;
