export const productConstants = {
  PRODUCT_LIST_REQUEST: "PRODUCT_LIST_REQUEST",
  PRODUCT_LIST_SUCCESS: "PRODUCT_LIST_SUCCESS",
  PRODUCT_LIST_FAILURE: "PRODUCT_LIST_FAILURE",

  PRODUCT_FILTER: "PRODUCT_FILTER",

  PRODUCT_DETAILS_REQUEST: "PRODUCT_DETAILS_REQUEST",
  PRODUCT_DETAILS_SUCCESS: "PRODUCT_DETAILS_SUCCESS",
  PRODUCT_DETAILS_FAILURE: "PRODUCT_DETAILS_FAILURE",

  PRODUCT_VALUATION_REQUEST: "PRODUCT_VALUATION_REQUEST",
  PRODUCT_VALUATION_SUCCESS: "PRODUCT_VALUATION_SUCCESS",
  PRODUCT_VALUATION_FAILURE: "PRODUCT_VALUATION_FAILURE",

  GET_EXIST_MODEL_REQUEST: "GET_EXIST_MODEL_REQUEST",
  GET_EXIST_MODEL_SUCCESS: "GET_EXIST_MODEL_SUCCESS",
  GET_EXIST_MODEL_FAILURE: "GET_EXIST_MODEL_FAILURE",

  GET_SERIES_OPTIONS_REQUEST: "GET_SERIES_OPTIONS_REQUEST",
  GET_SERIES_OPTIONS_SUCCESS: "GET_SERIES_OPTIONS_SUCCESS",
  GET_SERIES_OPTIONS_FAILURE: "GET_SERIES_OPTIONS_FAILURE",
};
