import { authHeader } from '../_helpers';
import * as FormData from "form-data";

export const orderService = {
  getUserOrder,
  updateOrder,
  _delete,
  uploadOrderDocument,
  getCancelReason,
  CancelOrder,
  updateOrderRemittance,
  getOrderWithRemittance,
  getUserOrdersList,
};

function getUserOrder(userId, orderId) {
  var formData = new FormData();
  formData.append("userId", userId);
  formData.append("id", orderId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/orders/userOrderDetails/`,
    requestOptions
  ).then(handleResponse);
}

function getUserOrdersList(userId) {
  var formData = new FormData();
  formData.append("userId", userId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/orders/ordersByUserId/`,
    requestOptions
  ).then(handleResponse);
}

function updateOrder(orderData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: orderData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/orders/updateOrderItems/`,
    requestOptions
  ).then(handleResponse);
}

function uploadOrderDocument(orderData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: orderData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/orders/updateOrderDocuments/`,
    requestOptions
  ).then(handleResponse);
}

function CancelOrder(orderData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: orderData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/orders/cancelOrder/`,
    requestOptions
  ).then(handleResponse);
}

function getCancelReason() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/orders/cancelReason/`,
    requestOptions
  ).then(handleResponse);
}

function updateOrderRemittance(remittanceData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: remittanceData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/orders/updateOrderPaymentMethod/`,
    requestOptions
  ).then(handleResponse);
}
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/users/${id}`,
      requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function getOrderWithRemittance(orderId, userId) {
  var formData = new FormData();
  formData.append("id", orderId);
  formData.append("userId", userId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/orders/userOrderDetailsWithRemittance/`,
    requestOptions
  ).then(handleResponse);
}