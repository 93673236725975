import alertModal from "./AlertModal";
import LoginModal from "./LoginModal";
import RegistrationModal from "./RegistrationModal";
import AcceptQuoteModal from "./AcceptQuoteModal";
import ForgotPassModal from "./ForgotPassModal";

const modalTypes = {
  alertModal,
  LoginModal,
  RegistrationModal,
  AcceptQuoteModal,
  ForgotPassModal,
};

export default modalTypes;
