import React from 'react';
import { userConstants } from '../_constants';
import { userService } from '../_services';
import { hideModal } from "./";
//import { history } from '../_helpers';
import { toast } from "react-toastify";

export const userActions = {
  login,
  logout,
  socialLogin,
  register,
  getAll,
  getProfile,
  delete: _delete,
  updateProfile,
  changePassword,
  changeEmail,
  forgotpass,
  newsletterSignup,
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    //history.push('/');
                    //window.location.reload(true);
                  dispatch(hideModal());
                  toast.success("Login Successful!");
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                  const CustomToastWithHtml = () => (
                    <div dangerouslySetInnerHTML={{ __html: error }}></div>
                  );
                  toast.error(CustomToastWithHtml);
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function socialLogin(user, provider) {
    return dispatch => {
        dispatch(request({ user }));

        userService.socialLogin(user, provider).then(
          (user) => {
            dispatch(success(user));
            //history.push('/');
            dispatch(hideModal());
            toast.success("Login Successful!");
          },
          (error) => {
            dispatch(failure(error.toString()));
            //dispatch(alertActions.error(error.toString()));
            const CustomToastWithHtml = () => (
              <div dangerouslySetInnerHTML={{ __html: error }}></div>
            );
            toast.error(CustomToastWithHtml);
          }
        );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(username, password, data_source, email_subscribe) {
  return (dispatch) => {
    dispatch(request(username));

    userService
      .register(username, password, data_source, email_subscribe)
      .then(
        (user) => {
          dispatch(success(user));
          /* history.push('/login'); */
          dispatch(hideModal());
          toast.success("Successfully Registered");
          //window.location.reload(true);
        },
        (error) => {
          dispatch(failure(error.toString())); 
          //dispatch(alertActions.error(error.toString()));
          const CustomToastWithHtml = () => (
            <div dangerouslySetInnerHTML={{ __html: error }}></div>
          );
          toast.error(CustomToastWithHtml);
        }
      );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function forgotpass(username) {
    return dispatch => {
        dispatch(request({ username }));

        userService.resetPassword(username)
            .then(
                user => { 
                    dispatch(success(user));
                    //history.push('/');
                    //window.location.reload(true);
                  toast.success(
                    "A new password has been generated and sent to your email."
                  );
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                  const CustomToastWithHtml = () => (
                    <div dangerouslySetInnerHTML={{ __html: error }}></div>
                  );
                  toast.error(CustomToastWithHtml);
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_PASSWORD_REQUEST, user }; }
    function success(user) { return { type: userConstants.FORGOT_PASSWORD_SUCCESS, user }; }
    function failure(error) { return { type: userConstants.FORGOT_PASSWORD_FAILURE, error }; }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getProfile(id) {
  return (dispatch) => {
    dispatch(request());

    userService.getById(id).then(
      (userProfile) => dispatch(success(userProfile)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETPROFILE_REQUEST };
  }
  function success(userProfile) {
    return { type: userConstants.GETPROFILE_SUCCESS, userProfile };
  }
  function failure(error) {
    return { type: userConstants.GETPROFILE_FAILURE, error };
  }
}

function updateProfile(
  firstName,
  lastName,
  phone,
  address2,
  address1,
  city,
  state,
  pincode,
  userId
) {
  return (dispatch) => {
    dispatch(request());

    userService
      .update(
        firstName,
        lastName,
        phone,
        address2,
        address1,
        city,
        state,
        pincode,
        userId
      )
      .then(
        (user) => {
          dispatch(success(user));
          toast.success("Profile Successfully updated");
        },
        (error) => {
          dispatch(failure(error.toString()));
          toast.error(
            "Error in saving Profile Information, Please try again later."
          );
        }
      );
  };

  function request() {
    return { type: userConstants.UPDATE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.UPDATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}

function changePassword(newPassword, confirmPassword, userId) {
  return (dispatch) => {
    dispatch(request());

    userService.changePassword(newPassword, confirmPassword, userId).then (
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.UPDATE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.UPDATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}

function changeEmail(newEmail, confirmEmail, userId) {
  return (dispatch) => {
    dispatch(request());

    userService.changeEmail(newEmail, confirmEmail, userId).then(
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.UPDATE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.UPDATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function newsletterSignup(formData) {
  return (dispatch) => {
    dispatch(request());
    userService.newsletterSignup(formData).then(
      (status) => {
        dispatch(success(status));
        toast.success(CustomToastWithHtml(status.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(CustomToastWithHtml(error));
      }
    );
  };

  function request() {
    return { type: userConstants.NEWSLETTER_SIGNUP_REQUEST };
  }
  function success(status) {
    return {
      type: userConstants.NEWSLETTER_SIGNUP_SUCCESS,
      status,
    };
  }
  function failure(error) {
    return { type: userConstants.NEWSLETTER_SIGNUP_FAILURE, error };
  }
}

const CustomToastWithHtml = (message) => (
  <div dangerouslySetInnerHTML={{ __html: message }}></div>
);