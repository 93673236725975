import React, { Component } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import BackDrop from "./BackDrop";
import { Formik } from "formik";
import * as Yup from "yup";
import LoginButton from "./LoginButton";

const schema = Yup.object({
  username: Yup.string()
    .required("Email Address is required")
    .email("Please enter valid email address"),
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { loggingIn, user } = this.props;
    
    return (
      
            <Formik
              validationSchema={schema}
              onSubmit={async (values) => {
                this.props.forgotpass(values.username);
              }}
              initialValues={{
                username: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="username"
                      placeholder="yourmail@example.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.username && !errors.username}
                      isInvalid={!!errors.username}
                      value={values.username}
                    />
                    <Form.Control.Feedback
                      type={errors.username ? "invalid" : "valid"}
                    >
                      {errors.username ? errors.username : "Looks good!"}
                    </Form.Control.Feedback>
                  </Form.Group>
                  
                    {loggingIn ? (
                      <BackDrop />
                    ) : (
                      <>
                        <Form.Group>
                          <Button type="submit" className="w-100 btn btn-default" disabled={loggingIn}>
                            Reset Password
                          </Button>{" "}
                          {/* Return to <LoginButton /> */}
                        </Form.Group>
                      </>
                    )}
                  
                </Form>
              )}
            </Formik>
          
    );
  }
}

const mapState = (state) => ({
  loggingIn: state.authentication.loggingIn,
  user: state.authentication.user,
});

const actionCreators = {
  forgotpass: userActions.forgotpass,
};

export default connect(mapState, actionCreators)(ForgotPassword);