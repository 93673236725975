export const remittanceConstants = {
  REMITTANCE_REQUEST: "REMITTANCE_REQUEST",
  REMITTANCE_SUCCESS: "REMITTANCE_SUCCESS",
  REMITTANCE_FAILURE: "REMITTANCE_FAILURE",

  USER_REMITTANCE_REQUEST: "USER_REMITTANCE_REQUEST",
  USER_REMITTANCE_SUCCESS: "USER_REMITTANCE_SUCCESS",
  USER_REMITTANCE_FAILURE: "USER_REMITTANCE_FAILURE",

  SAVE_USER_REMITTANCE_REQUEST: "SAVE_USER_REMITTANCE_REQUEST",
  SAVE_USER_REMITTANCE_SUCCESS: "SAVE_USER_REMITTANCE_SUCCESS",
  SAVE_USER_REMITTANCE_FAILURE: "SAVE_USER_REMITTANCE_FAILURE",
};
