import { locationConstants } from "../_constants";

export function locations(state = {}, action) {
  switch (action.type) {
    case locationConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case locationConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.stateList,
      };
    case locationConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}