import React, { useState } from "react";
import Login from "../Login";
import { Link } from "@material-ui/core";
import OtpLogin from "../OtpLogin";
import ForgotPassword from "../ForgotPassword";
import Registration from "../Registration";
// import { hideModal } from "../_actions";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Image from 'next/image'

const LoginModal = ({ onClose, closeModal, title, message }) => {

  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  
  

  const s3_front_end_image =
    "https://budlisell.s3.ap-south-1.amazonaws.com/assets/themes/forntend/default/img/";
  return (
    <>

      <div className="p-3 loginregmodal">
        <div className="text-center mb-3">
          <Link href="/" className="d-inline-flex logo">
            <Image
                  src={`${s3_front_end_image}budli-logo-v4.svg`}
                  width={135}
                  height={78}
                  alt="budli"
                />
          </Link>
        </div>
        <div id="accordion">
          <div className="">
            <div
              id="collapseOne"
              className={`collapse ${activeIndex === 0 ? "show" : ""}`}
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="">
                <Tabs
                  defaultActiveKey="home"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="home" title="Sign In">
                    <Login />
                    <div className="mt-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <div
                            className="collapsed text-center curser-pointer"
                            onClick={() => toggleAccordion(1)}
                            aria-expanded={activeIndex === 1}
                            aria-controls="collapseTwo"
                          >
                            Forgot Password?
                          </div>
                        </div>
                        <div>
                          <div
                            className="text-center collapsed curser-pointer"
                            onClick={() => toggleAccordion(2)}
                            aria-expanded={activeIndex === 2}
                            aria-controls="collapseThree"
                          >
                            Log in with OTP
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="profile" title="Sign Up">
                    <Registration />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="">
            <div
              id="collapseTwo"
              className={`collapse ${activeIndex === 1 ? "show" : ""}`}
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div className="">
                <ForgotPassword />
                <div className="mt-2">
                  <div
                    className="text-center curser-pointer"
                    onClick={() => toggleAccordion(0)}
                    aria-expanded={activeIndex === 0}
                    aria-controls="collapseOne"
                  >
                    Return to Log in
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div
              id="collapseThree"
              className={`collapse ${activeIndex === 2 ? "show" : ""}`}
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div className="">
              
                <OtpLogin />
              
                <div className="mt-2">
                  <div
                    className="text-center curser-pointer"
                    onClick={() => toggleAccordion(0)}
                    aria-expanded={activeIndex === 0}
                    aria-controls="collapseOne"
                  >
                    Return to Log in
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    
  );
};

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
});

export default LoginModal;
