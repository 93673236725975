import { remittanceConstants } from "../_constants";
import { remittanceService } from "../_services";
import { toast } from "react-toastify";

export const remittanceActions = {
  getRemittanceTypes,
  getActiveRemittanceTypes,
  getUserRemittance,
  updateUserRemittance,
};

function getRemittanceTypes() {
  return (dispatch) => {
    dispatch(request());

    remittanceService.getRemittanceTypes().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: remittanceConstants.REMITTANCE_REQUEST };
  }
  function success(items) {
    return { type: remittanceConstants.REMITTANCE_SUCCESS, items };
  }
  function failure(error) {
    return { type: remittanceConstants.REMITTANCE_FAILURE, error };
  }
}

function getActiveRemittanceTypes() {
  return (dispatch) => {
    dispatch(request());

    remittanceService.getActiveRemittanceTypes().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: remittanceConstants.REMITTANCE_REQUEST };
  }
  function success(items) {
    return { type: remittanceConstants.REMITTANCE_SUCCESS, items };
  }
  function failure(error) {
    return { type: remittanceConstants.REMITTANCE_FAILURE, error };
  }
}

function getUserRemittance(userId) {
  return (dispatch) => {
    dispatch(request());

    remittanceService.getUserRemittance(userId).then(
      (remittance) => dispatch(success(remittance)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: remittanceConstants.USER_REMITTANCE_REQUEST };
  }
  function success(remittance) {
    return { type: remittanceConstants.USER_REMITTANCE_SUCCESS, remittance };
  }
  function failure(error) {
    return { type: remittanceConstants.USER_REMITTANCE_FAILURE, error };
  }
}

function updateUserRemittance(formData) {
  return (dispatch) => {
    dispatch(request());

    remittanceService.updateUserRemittance(formData).then(
      (updateStatus) => {
        dispatch(success(updateStatus));
        toast.success(updateStatus.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Error in saving remittance! try again later.");
      }
    );
  };

  function request() {
    return { type: remittanceConstants.SAVE_USER_REMITTANCE_REQUEST };
  }
  function success(updateStatus) {
    return {
      type: remittanceConstants.SAVE_USER_REMITTANCE_SUCCESS,
      updateStatus,
    };
  }
  function failure(error) {
    return { type: remittanceConstants.SAVE_USER_REMITTANCE_FAILURE, error };
  }
}