import { cartConstants } from "../_constants";
import { cartService } from "../_services";
import { toast } from "react-toastify";
import Router from "next/router";

export const cartActions = {
  applyCouponCode,
  submitCart,
};

function applyCouponCode(formData) {
  return (dispatch) => {
    dispatch(request());
    cartService.applyCouponCode(formData).then(
      (cartItem) => {
        dispatch(success(cartItem));
        toast.success("Coupon successfully applied.");
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Error in apply coupon code! try again later.");
      }
    );
  };

  function request() {
    return { type: cartConstants.APLY_COUPON_REQUEST };
  }
  function success(cartItem) {
    return {
      type: cartConstants.APLY_COUPON_SUCCESS,
      cartItem,
    };
  }
  function failure(error) {
    return { type: cartConstants.APLY_COUPON_FAILURE, error };
  }
}

function submitCart(formData) {
  return (dispatch) => {
    dispatch(request());
    cartService.submitCart(formData).then(
      (orderDetails) => {
        dispatch(success(orderDetails));
        toast.success("Order successfully placed.");
        Router.push(`/order-success`);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Error in submit order! try again later.");
      }
    );
  };

  function request() {
    return { type: cartConstants.SUBMIT_CART_REQUEST };
  }
  function success(orderDetails) {
    return {
      type: cartConstants.SUBMIT_CART_SUCCESS,
      orderDetails,
    };
  }
  function failure(error) {
    return { type: cartConstants.SUBMIT_CART_FAILURE, error };
  }
}