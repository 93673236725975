import { documentConstants } from "../_constants";
import { documentService } from "../_services";
import { toast } from "react-toastify";

export const documentActions = {
  getUploadDocumentTypes,
  uploadUserDocument,
  getUserDocument,
  deleteUserDocument,
};

function getUploadDocumentTypes() {
  return (dispatch) => {
    dispatch(request());

    documentService.getUploadDocumentTypes().then(
      (documentsTypes) => dispatch(success(documentsTypes)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_TYPES_REQUEST };
  }
  function success(documentsTypes) {
    return { type: documentConstants.DOCUMENT_TYPES_SUCCESS, documentsTypes };
  }
  function failure(error) {
    return { type: documentConstants.DOCUMENT_TYPES_FAILURE, error };
  }
}

function uploadUserDocument(formData) {
  return (dispatch) => {
    dispatch(request());

    documentService.uploadUserDocument(formData).then(
      (uploadStatus) => {
        dispatch(success(uploadStatus));
        toast.success(uploadStatus.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Error in file upload! try again later.");
      }
    );
  };

  function request() {
    return { type: documentConstants.UPLOAD_USER_DOCUMENT_REQUEST };
  }
  function success(uploadStatus) {
    return {
      type: documentConstants.UPLOAD_USER_DOCUMENT_SUCCESS,
      uploadStatus,
    };
  }
  function failure(error) {
    return { type: documentConstants.UPLOAD_USER_DOCUMENT_FAILURE, error };
  }
}

function getUserDocument (userId) {
  return (dispatch) => {
    dispatch(request());

    documentService.getUserDocument(userId).then(
      (documents) => dispatch(success(documents)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: documentConstants.USER_DOCUMENT_REQUEST };
  }
  function success(documents) {
    return {
      type: documentConstants.USER_DOCUMENT_SUCCESS,
      documents,
    };
  }
  function failure(error) {
    return { type: documentConstants.USER_DOCUMENT_FAILURE, error };
  }
}

function deleteUserDocument(documentId, userId) {
  return (dispatch) => {
    dispatch(request());

    documentService.deleteUserDocument(documentId).then(
      (deleteStatus) => {
        dispatch(success(deleteStatus));
        dispatch(getUserDocument(userId));
        toast.success(deleteStatus.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Error in delete document! try again later.");
      }
    );
  };

  function request() {
    return { type: documentConstants.DELETE_USER_DOCUMENT_REQUEST };
  }
  function success(deleteStatus) {
    return {
      type: documentConstants.DELETE_USER_DOCUMENT_SUCCESS,
      deleteStatus,
    };
  }
  function failure(error) {
    return { type: documentConstants.DELETE_USER_DOCUMENT_FAILURE, error };
  }
}