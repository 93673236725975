import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSession, signIn } from "next-auth/react";
import { hideModal } from "../_actions";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import BackDrop from "./BackDrop";
import VerificationCodeInput from "./VerificationCodeInput";
import { authHeader } from "../_helpers";
import OtpLogin from "./OtpLogin";



const schema = Yup.object({
  username: Yup.string()
    .required("Username is required")
    .email("Please enter valid email address"),
  password: Yup.string().required("Password is required"),
});

function Login({ hideModal }) {
  const { data: session, status } = useSession();
  const [hasStartedVerification, setHasStartedVerification] = useState(false);
  const [credentials, setCredentials] = useState();

  if (status == "authenticated") {
    hideModal();
  }
  const startVerification = async ({ mobile, consent }) => {
    var formData = new FormData();
    formData.append("mobile", mobile);
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: formData,
    };
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}auth/sendOtp`,
      requestOptions
    );
    const result = await res.json();
    if (res.ok) {
      setCredentials({ mobile, consent });
      setHasStartedVerification(true);
      toast.success(result[3]);
    } else {
      toast.error(result.message);
    }
  };

  const resendHandler = async () => {
    var formData = new FormData();
    formData.append("mobile", credentials.mobile);
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: formData,
    };
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}auth/sendOtp`,
      requestOptions
    );
    const result = await res.json();
    if (res.ok) {
      setHasStartedVerification(true);
      toast.success(result[3]);
    } else {
      toast.error(result.message);
    }
  };

  const checkVerification = async ({ verificationCode }) => {
    let mobile = credentials.mobile;
    let consent = credentials.consent;
    var formData = new FormData();
    formData.append("mobile", mobile);
    formData.append("verificationCode", verificationCode);
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: formData,
    };
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}auth/verifyOtp`,
      requestOptions
    );
    const result = await res.json();
    if (res.ok) {
      await signIn("otp-login", {
        redirect: false,
        mobile,
        consent,
      });
      hideModal();
    } else {
      toast.error(result.message);
    }
  };

  


  return (
    <>
        <button className="btnico clsmodal" onClick={hideModal}>
          <svg fill="#BC1C5C" height="18" viewBox="0 0 329.26933 329" width="18" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
        </button>
            <div className="">
              
            {!hasStartedVerification ? (
                        <OtpLogin onSubmit={startVerification} />
                      ) : (
                        <VerificationCodeInput
                          onSubmit={checkVerification}
                          onResend={resendHandler}
                        />
                      )} 
                      <div className="text-center mt-1">
                        <div>------------------ OR ------------------</div>
                      </div>
                      <Formik
                        validationSchema={schema}
                        onSubmit={async (values) => {
                          let username = values.username;
                          let password = values.password;
                          const res = await signIn("credentials-login", {
                            redirect: false,
                            username,
                            password,
                          });
                          if (res.ok) {
                            toast.success("Successfully Logged In");
                            hideModal();
                          } else {
                            toast.error("Wrong credentials. Try again.");
                          }
                        }}
                        initialValues={{
                          username: "",
                          password: "",
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          errors,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group>
                              <Form.Label>Email Address</Form.Label>
                              <Form.Control
                                type="email"
                                name="username"
                                placeholder="yourmail@gmail.com"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.username && !errors.username}
                                isInvalid={!!errors.username}
                                value={values.username}
                              />
                              <Form.Control.Feedback
                                type={errors.username ? "invalid" : "valid"}
                              >
                                {errors.username ? errors.username : "Looks good!"}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.password && !errors.password}
                                isInvalid={!!errors.password}
                                value={values.password}
                              />
                              <Form.Control.Feedback
                                type={errors.password ? "invalid" : "valid"}
                              >
                                {errors.password ? errors.password : "Looks good!"}
                              </Form.Control.Feedback>
                            </Form.Group>
                            
                              <Form.Group>
                                {status == "loading" ? (
                                  <BackDrop />
                                ) : (
                                  <Button type="submit" className="w-100 btn btn-default">
                                    Log in
                                  </Button>
                                )}
                                {/* <ForgotPassButton /> */}
                                
                              </Form.Group>
                            
                            {/* <Form.Row>
                              New User? <RegisterButton />
                            </Form.Row> */}
                          </Form>
                        )}
                      </Formik>
                    
                    <div className="text-center">
                      <div>------------------ OR ------------------</div>
                    </div>
                  <div className="d-flex flex-column gap-3 mt-3">
                    {/* <FacebookLoginButton className="m-0 w-100"
                      onClick={() => {
                        signIn("facebook", { redirect: false });
                        hideModal();
                      }}
                    /> */}
                    <GoogleLoginButton className="m-0 w-100 body-font d-flex justify-content-center"
                      onClick={() => {
                        signIn("google", { redirect: false });
                        hideModal();
                      }}
                    />
                  </div>
                  
                  
                      {/* 
                      {!hasStartedVerification ? (
                        <OtpLogin onSubmit={startVerification} />
                      ) : (
                        <VerificationCodeInput
                          onSubmit={checkVerification}
                          onResend={resendHandler}
                        />
                      )} 
                      <div className="text-center">
                        <div>------------------ OR ------------------</div>
                      </div>
                      */}
                    
                
            </div>
      
        
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
});

export default connect(null, mapDispatchToProps)(Login);
