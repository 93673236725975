import { orderConstants } from '../_constants';

export function orders(state = {}, action) {
  switch (action.type) {
    case orderConstants.GET_ORDER_REQUEST:
      return {
        loading: true,
      };
    case orderConstants.GET_ORDER_SUCCESS:
      return {
        loading: false,
        order: action.order,
      };
    case orderConstants.GET_ORDER_FAILURE:
      return {
        error: action.error,
      };
    case orderConstants.UPDATE_ORDER_REQUEST:
      return {
        submitting: true,
      };
    case orderConstants.UPDATE_ORDER_SUCCESS:
      return {
        submitting: false,
        updateStatus: action.order,
      };
    case orderConstants.UPDATE_ORDER_FAILURE:
      return {
        error: action.error,
      };
    case orderConstants.CANCEL_REASON_REQUEST:
      return {
        submitting: true,
      };
    case orderConstants.CANCEL_REASON_SUCCESS:
      return {
        submitting: false,
        reasonList: action.reasons,
      };
    case orderConstants.CANCEL_REASON_FAILURE:
      return {
        error: action.error,
      };
    case orderConstants.CANCEL_ORDER_REQUEST:
      return {
        submitting: true,
      };
    case orderConstants.CANCEL_ORDER_SUCCESS:
      return {
        submitting: false,
        responseStatus: action.reasons,
      };
    case orderConstants.CANCEL_ORDER_FAILURE:
      return {
        error: action.error,
      };
    case orderConstants.ORDER_REMITTANCE_REQUEST:
      return {
        submitting: true,
      };
    case orderConstants.ORDER_REMITTANCE_SUCCESS:
      return {
        submitting: false,
        response: action.response,
      };
    case orderConstants.ORDER_REMITTANCE_FAILURE:
      return {
        error: action.error,
      };
    case orderConstants.GET_USER_ORDERS_REQUEST:
      return {
        loading: true,
      };
    case orderConstants.GET_USER_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderList: action.orders,
      };
    case orderConstants.GET_USER_ORDERS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}