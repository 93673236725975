import { remittanceConstants } from "../_constants";

export function remittance(state = {}, action) {
  switch (action.type) {
    case remittanceConstants.REMITTANCE_REQUEST:
      return {
        submitting: true,
      };
    case remittanceConstants.REMITTANCE_SUCCESS:
      return {
        ...state,
        submitting: false,
        items: action.items,
      };
    case remittanceConstants.REMITTANCE_FAILURE:
      return {
        error: action.error,
      };
    case remittanceConstants.USER_REMITTANCE_REQUEST:
      return {
        loadingUserRemittance: true,
      };
    case remittanceConstants.USER_REMITTANCE_SUCCESS:
      return {
        ...state,
        loadingUserRemittance: false,
        userRemittance: action.remittance,
      };
    case remittanceConstants.USER_REMITTANCE_FAILURE:
      return {
        error: action.error,
      };
    case remittanceConstants.SAVE_USER_REMITTANCE_REQUEST:
      return {
        savingRemittance: true,
      };
    case remittanceConstants.SAVE_USER_REMITTANCE_SUCCESS:
      return {
        ...state,
        savingRemittance: false,
        updateStatus: action.updateStatus,
      };
    case remittanceConstants.SAVE_USER_REMITTANCE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}