import { enquiryConstants } from "../_constants";
import { enquiryService } from "../_services";
import Router from "next/router";
//import { history } from '../_helpers';
import { toast } from "react-toastify";

export const enquiryActions = {
  submitD2DForm,
  getD2dPartnerLogo,
  getD2dPartnerContent,
  submitSalesEnquiry,
  getRaqLaptopoptions,
  submitRaq,
  getUserEnquiries,
  getUserEnquiryDetails,
  applyCouponCode,
  acceptQuote,
  submitContactForm,
  submitBusinessContactForm,
};

function submitD2DForm(formData) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.submitD2D(formData).then(
      (status) => {
        dispatch(success(status));
        toast.success(CustomToastWithHtml(status.message));
        Router.push(`/`);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(CustomToastWithHtml(error));
      }
    );
  };

  function request() {
    return { type: enquiryConstants.SUBMIT_D2D_REQUEST };
  }
  function success(status) {
    return {
      type: enquiryConstants.SUBMIT_D2D_SUCCESS,
      status,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.SUBMIT_D2D_FAILURE, error };
  }
}

function getD2dPartnerLogo() {
  return (dispatch) => {
    dispatch(request());
    enquiryService.getPartnerLogo().then(
      (logos) => {
        dispatch(success(logos));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: enquiryConstants.D2D_PARTNER_LOGO_REQUEST };
  }
  function success(logos) {
    return {
      type: enquiryConstants.D2D_PARTNER_LOGO_SUCCESS,
      logos,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.D2D_PARTNER_LOGO_FAILURE, error };
  }
}

function getD2dPartnerContent(partner) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.getPartnerContent(partner).then(
      (content) => {
        dispatch(success(content));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: enquiryConstants.D2D_PARTNER_CONTENT_REQUEST };
  }
  function success(content) {
    return {
      type: enquiryConstants.D2D_PARTNER_CONTENT_SUCCESS,
      content,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.D2D_PARTNER_CONTENT_FAILURE, error };
  }
}

function submitSalesEnquiry(formData) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.submitSalesEnquiry(formData).then(
      (status) => {
        dispatch(success(status));
        toast.success(CustomToastWithHtml(status.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(CustomToastWithHtml(error));
      }
    );
  };

  function request() {
    return { type: enquiryConstants.SUBMIT_SALES_ENQUIRY_REQUEST };
  }
  function success(status) {
    return {
      type: enquiryConstants.SUBMIT_SALES_ENQUIRY_SUCCESS,
      status,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.SUBMIT_SALES_ENQUIRY_FAILURE, error };
  }
}

function getRaqLaptopoptions() {
  return (dispatch) => {
    dispatch(request());
    enquiryService.getRaqLaptopOptions().then(
      (options) => {
        dispatch(success(options));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: enquiryConstants.ENQUIRY_LAPTOP_OPTION_REQUEST };
  }
  function success(options) {
    return {
      type: enquiryConstants.ENQUIRY_LAPTOP_OPTION_SUCCESS,
      options,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.ENQUIRY_LAPTOP_OPTION_FAILURE, error };
  }
}

function submitRaq(formData) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.submitRaq(formData).then(
      (status) => {
        dispatch(success(status));
        toast.success(CustomToastWithHtml(status.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(CustomToastWithHtml(error));
      }
    );
  };

  function request() {
    return { type: enquiryConstants.SUBMIT_SALES_ENQUIRY_REQUEST };
  }
  function success(status) {
    return {
      type: enquiryConstants.SUBMIT_SALES_ENQUIRY_SUCCESS,
      status,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.SUBMIT_SALES_ENQUIRY_FAILURE, error };
  }
}

function getUserEnquiries(userId) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.getUserEnquiries(userId).then(
      (enquiries) => {
        dispatch(success(enquiries));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: enquiryConstants.GET_USER_ENQUIRY_REQUEST };
  }
  function success(enquiries) {
    return {
      type: enquiryConstants.GET_USER_ENQUIRY_SUCCESS,
      enquiries,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.GET_USER_ENQUIRY_FAILURE, error };
  }
}

function getUserEnquiryDetails(enquryId, userId) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.getUserEnquiryDetails(enquryId, userId).then(
      (enquiry) => {
        dispatch(success(enquiry));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: enquiryConstants.USER_ENQUIRY_REQUEST };
  }
  function success(enquiry) {
    return {
      type: enquiryConstants.USER_ENQUIRY_SUCCESS,
      enquiry,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.USER_ENQUIRY_FAILURE, error };
  }
}

function applyCouponCode (formData) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.applyCouponCode(formData).then(
      (couponData) => {
        dispatch(success(couponData));
        toast.success("Coupon successfully applied.");
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(CustomToastWithHtml(error));
      }
    );
  };

  function request() {
    return { type: enquiryConstants.ENQUIRY_COUPON_REQUEST };
  }
  function success(couponData) {
    return {
      type: enquiryConstants.ENQUIRY_COUPON_SUCCESS,
      couponData,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.ENQUIRY_COUPON_FAILURE, error };
  }
}

function acceptQuote(formData) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.acceptQuote(formData).then(
      (status) => {
        dispatch(success(status));
        toast.success(CustomToastWithHtml(status.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(CustomToastWithHtml(error));
      }
    );
  };

  function request() {
    return { type: enquiryConstants.ACCEPT_QUOTE_REQUEST };
  }
  function success(status) {
    return {
      type: enquiryConstants.ACCEPT_QUOTE_SUCCESS,
      status,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.ACCEPT_QUOTE_FAILURE, error };
  }
}

function submitContactForm(formData) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.submitContact(formData).then(
      (status) => {
        dispatch(success(status));
        toast.success(CustomToastWithHtml(status.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(CustomToastWithHtml(error));
      }
    );
  };

  function request() {
    return { type: enquiryConstants.SUBMIT_CONTACT_REQUEST };
  }
  function success(status) {
    return {
      type: enquiryConstants.SUBMIT_CONTACT_SUCCESS,
      status,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.SUBMIT_CONTACT_FAILURE, error };
  }
}

function submitBusinessContactForm(formData) {
  return (dispatch) => {
    dispatch(request());
    enquiryService.submitBusinessContact(formData).then(
      (status) => {
        dispatch(success(status));
        toast.success(status.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(
          "Could not complete Contcat us submission! try again later."
        );
      }
    );
  };

  function request() {
    return { type: enquiryConstants.SUBMIT_CONTACT_REQUEST };
  }
  function success(status) {
    return {
      type: enquiryConstants.SUBMIT_CONTACT_SUCCESS,
      status,
    };
  }
  function failure(error) {
    return { type: enquiryConstants.SUBMIT_CONTACT_FAILURE, error };
  }
}

const CustomToastWithHtml = (message) => (
  <div dangerouslySetInnerHTML={{ __html: message }}></div>
);