import { authHeader } from '../_helpers';

export const remittanceService = {
  getRemittanceTypes,
  getActiveRemittanceTypes,
  getUserRemittance,
  updateUserRemittance,
};

function getRemittanceTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/payment_methods/paymentMethods/`,
    requestOptions
  ).then(handleResponse);
}

function getActiveRemittanceTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/payment_methods/activePaymentMethods/`,
    requestOptions
  ).then(handleResponse);
}

function getUserRemittance(userId) {
  var formData = new FormData();
  formData.append("userId", userId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/payment_methods/userPaymentMethod/`,
    requestOptions
  ).then(handleResponse);
}

function updateUserRemittance(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/payment_methods/updateUserRemittance/`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}