import { authHeader } from "../_helpers";
import * as FormData from "form-data";

export const enquiryService = {
  submitD2D,
  getPartnerLogo,
  getPartnerContent,
  submitSalesEnquiry,
  getRaqLaptopOptions,
  submitRaq,
  getUserEnquiries,
  getUserEnquiryDetails,
  applyCouponCode,
  acceptQuote,
  submitContact,
  submitBusinessContact,
};

function submitD2D(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}devicetodream/deviceToDreamSubmit`,
    requestOptions
  )
    .then(handleResponse)
    .then((status) => {
      return status;
    });
}

function getPartnerLogo() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}devicetodream/getPartnerLogo`,
    requestOptions
  )
    .then(handleResponse)
    .then((logos) => {
      return logos;
    });
}

function getPartnerContent(partner) {
  var form = new FormData();
  form.append("partner", partner);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: form,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}devicetodream/getPartnerContent`,
    requestOptions
  ).then(handleResponse);
}

function submitSalesEnquiry(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}enquiries/submitSalesEnquiries`,
    requestOptions
  )
    .then(handleResponse)
    .then((status) => {
      return status;
    });
}

function getRaqLaptopOptions() {
  
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}enquiries/getRaqLaptopOptions`,
    requestOptions
  ).then(handleResponse);
}

function submitRaq(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}enquiries/submitRaq`,
    requestOptions
  )
    .then(handleResponse)
    .then((status) => {
      return status;
    });
}

function getUserEnquiries(userId) {
  var formData = new FormData();
  formData.append("userId", userId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}enquiries/enquiriesByUserId`,
    requestOptions
  ).then(handleResponse);
}

function getUserEnquiryDetails(enquryId, userId) {
  var formData = new FormData();
  formData.append("userId", userId);
  formData.append("id", enquryId);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}enquiries/userEnquiryDetails`,
    requestOptions
  ).then(handleResponse);
}

function applyCouponCode (formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}enquiries/applyRaqCoupon`,
    requestOptions
  )
    .then(handleResponse);
}

function acceptQuote(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}enquiries/acceptPriceQuote`,
    requestOptions
  )
    .then(handleResponse)
    .then((status) => {
      return status;
    });
}

function submitContact(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}enquiries/submitContactUs`,
    requestOptions
  )
    .then(handleResponse)
    .then((status) => {
      return status;
    });
}

function submitBusinessContact(formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}enquiries/submitBusinessContactUs`,
    requestOptions
  )
    .then(handleResponse)
    .then((status) => {
      return status;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}